<template>
  <div class="main-content">
    <div class="bar-box">
      <button class="font-16 sub-color" style="padding: 0px" @click="hideEvent">Cancel</button>
      <span class="font-18 Medium">Select Home Store</span>
      <button class="font-16 sub-color" v-if="showDone" @click="doneEvent" style="width: fit-content">Done</button>
      <button class="font-16 sub-color" v-else style="width: fit-content; opacity: 0">Done</button>
    </div>
    <div class="flex-box">
      {{ tip[tab] }}
    </div>
    <div class="content-list" :class="{ 'font-20': $store.state.isPC }" ref="list">
      <div v-if="tab == 1">
        <div v-for="(area, index) in areaList" :key="index">
          <div class="center-item" @click="selectAreaEvent(area)" v-if="area.parentId == 0">
            <div>{{ area.areaInfo }}</div>
            <i class="icon-arrowRight"></i>
          </div>
        </div>
      </div>
      <div v-if="tab == 2">
        <div v-for="(area, index) in areaList" :key="index">
          <div class="center-item" @click="selectSubAreaEvent(area)" v-if="area.parentId == areaId">
            <div>{{ area.areaInfo }}</div>
            <i class="icon-arrowRight"></i>
          </div>
        </div>
      </div>
      <div class="list-content" v-if="tab == 3">
        <div class="list-box" v-for="(store, index) in storeList" :key="index">
          <div class="detail">
            <p class="font-16 label-color Bold" :class="{ 'font-18': $store.state.isPC }">{{ store.name }}</p>
            <p class="m-ver-7 font-14 gery-color" v-if="$store.state.lat"><i class="icon-location"></i> {{ store.showDistance }}</p>
            <p class="font-14 gery-color">
              {{ store.address }}
            </p>
          </div>
          <button class="check-btn" :class="{ 'active': store.checked }" @click="checkEvent(store)"></button>
        </div>
        <div class="height250"></div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "../config";
export default {
  name: "storeList",
  mixins: [config.globalMixin],
  data() {
    return {
      storeList: [],
      storeName: "",
      result: [],
      tab: 1,
      areaId: "",
      subAreaId: "",
      areaList: [],
      tip: {
        1: "Choose State",
        2: "Choose Suburb",
        3: "Choose Store",
      },
      showDone: false,
      tick: require("@/assets/tick2.png"),
    };
  },
  props: ["selectId"],
  methods: {
    async getStoreEvent(id) {
      const objdata = this.getAuthObj();
      objdata.storeName = this.storeName;
      objdata.merchantAreaId = id;
      objdata.apiUrl = this.apiConfig.listStore;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.storeList = data.data.result;
      this.storeList.forEach(store => {
        if (store.storeId == this.selectId) {
          store.checked = true;
        } else {
          store.checked = false;
        }
        if (this.$store.state.lat) {
          store.distance = this.getDistanceFromLatLonInKm(store.lat, store.lng);
          store.showDistance = this.getShowDistance(store.distance);
        }
      });
      if (this.$store.state.lat) {
        this.storeList.sort((a, b) => {
          return a.distance - b.distance;
        });
      }
    },
    async getAreaEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.listMerchantArea;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.areaList = data.data.result;
    },
    selectAreaEvent(area) {
      this.tab = 2;
      this.areaId = area.merchantAreaId;
    },
    selectSubAreaEvent(area) {
      this.tab = 3;
      this.subAreaId = area.merchantAreaId;
      this.getStoreEvent(this.subAreaId);
    },
    getDistanceFromLatLonInKm(lat1, lon1) {
      const lat2 = this.$store.state.lat;
      const lon2 = this.$store.state.lng;
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    getShowDistance(d) {
      if (d > 1) {
        return Math.floor(d * 100) / 100 + " km";
      } else {
        return Math.floor(d * 1000) + " m";
      }
    },
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    cancelEvent() {
      this.storeName = "";
    },
    hideEvent() {
      this.$emit("hidemodel");
    },
    doneEvent() {
      this.storeList.forEach(store => {
        if (store.checked) {
          this.$emit("selectEvent", store);
          return;
        }
      });
      this.$emit("selectEvent", null);
    },
    checkEvent(store) {
      this.storeList.forEach(store => (store.checked = false));
      store.checked = true;
      this.showDone = true;
    },
  },
  mounted: function () {
    this.getAreaEvent();
    // this.$refs.list.style.height = window.innerHeight - 60 - 70 - 38 + 'px'
  },
};
</script>
<style lang="scss" scoped></style>
