<template>
  <ion-page id="page-content">
    <ion-modal
      @didDismiss="showStore = false"
      :is-open="showStore"
      :breakpoints="[0, 0.6, 1]"
      :initialBreakpoint="$store.state.isPC ? 1 : 0.6"
      css-class="home-store-modal"
    >
      <ion-content class="ion-padding">
        <storeListModel
          @selectEvent="selectEvent"
          @hidemodel="hidemodel"
          :selectId="selectStore.storeId"
        ></storeListModel>
      </ion-content>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar
        :bigtitle="true"
        title="Setup Profile"
        :showBack="true"
        :noboder="$store.state.isPC ? true : false"
      ></topbar>
      <div class="pc-box big">
        <div class="pc-min-content">
          <div class="content" style="padding-bottom: 0">
            <div class="title-PC" v-if="$store.state.isPC">Setup Profile</div>
            <div class="form-item" v-if="checkShowField(2)">
              <label class="required">First Name</label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="$store.state.userInfo.firstName"
                  maxlength="64"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div class="form-item" v-if="checkShowField(3)">
              <label class="required">Last Name</label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="$store.state.userInfo.lastName"
                  maxlength="64"
                  placeholder="Last Name"
                />
              </div>
            </div>

            <!-- Email Start -->
            <div class="form-item" v-if="false">
              <label class="required">Email</label>
              <div class="input-box" @click="goBindEvent(1)">
                <input
                  type="text"
                  class="whitedisabled"
                  disabled
                  v-model="$store.state.userInfo.email"
                  placeholder="Email Address"
                />
                <i
                  class="icon-arrowRight"
                  v-if="$store.state.userInfo.registerType != 1"
                ></i>
              </div>
            </div>
            <div class="form-item">
              <label
                :class="{
                  required:
                    $store.state.userInfo.registerType == 1 ||
                    $store.state.userInfo.isMarketingInfo ||
                    $store.state.userInfo.isPaperReceipt,
                }"
                >Email</label
              >
              <p class="tip">(Please provide if you want to receive digital receipts)</p>
              <div class="input-box">
                <ion-input
                  type="text"
                  class="whitedisabled"
                  :disabled="$store.state.userInfo.registerType == 1"
                  v-model.trim="$store.state.userInfo.email"
                  placeholder="Email Address"
                  @ionBlur="checkFormat('email')"
                  @ionInput="
                    $store.state.userInfo.email = $event.target.value
                      .trim()
                      .toLowerCase();
                    errorFormatEmail = false;
                  "
                />
                <!-- <i class="icon-arrowRight" v-if="$store.state.userInfo.registerType!=1"></i> -->
              </div>
              <p v-if="errorFormatEmail" class="tip red">Email format is incorrect</p>
            </div>
            <div class="form-item" v-if="$store.state.userInfo.registerType != 1">
              <label
                :class="{
                  required:
                    $store.state.userInfo.registerType == 1 ||
                    $store.state.userInfo.isMarketingInfo ||
                    $store.state.userInfo.isPaperReceipt,
                }"
                >Confirm Email</label
              >
              <div class="input-box">
                <input
                  type="text"
                  style="padding-left: 20px"
                  class="whitedisabled"
                  v-model.trim="confirmEmail"
                  @input="confirmEmail = confirmEmail.toLowerCase()"
                  placeholder="Email Address"
                  @blur="checkConfirm('email')"
                />
                <img
                  class="tick"
                  :src="tick"
                  v-if="$store.state.form.isEmailValidation"
                />
                <!-- <span v-else class="Medium" @click="verifyEvent(1)" style="color: #00a1de; cursor: pointer;">Verify</span> -->
                <!-- <i class="icon-arrowRight" v-if="$store.state.userInfo.registerType!=1"></i> -->
              </div>
              <p class="tip red" v-if="errorConfirm">Email do not match</p>
              <!-- <p v-if="!$store.state.form.isEmailValidation" class="verify-tip Medium">Pending for verification</p> -->
            </div>
            <!-- Email End -->

            <!-- Mobile Start -->
            <div class="form-item" v-if="false">
              <label>Mobile number</label>
              <div class="mobile-number item-table">
                <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div>
                <div class="tdcell phone" @click="goBindEvent(2)">
                  <input
                    class="whitedisabled"
                    disabled
                    maxlength="10"
                    v-model="$store.state.userInfo.phone"
                    type="number"
                    placeholder="Mobile number"
                  />
                  <i
                    class="icon-arrowRight"
                    v-if="$store.state.userInfo.registerType != 2"
                  ></i>
                </div>
              </div>
            </div>
            <div class="form-item">
              <label :class="{ required: $store.state.userInfo.registerType == 2 }"
                >Mobile number</label
              >
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <ion-input
                    class="whitedisabled"
                    v-model="$store.state.userInfo.phone"
                    :disabled="$store.state.userInfo.registerType == 2"
                    type="tel"
                    maxlength="10"
                    placeholder="Mobile number"
                    @ionBlur="checkFormat('phone')"
                    @ionInput="errorFormatPhone = false"
                  />
                  <!-- <i class="icon-arrowRight" v-if="$store.state.userInfo.registerType!=2"></i> -->
                </div>
              </div>
              <p v-if="errorFormatPhone" class="tip red">Mobile format is incorrect</p>
            </div>
            <div class="form-item" v-if="$store.state.userInfo.registerType != 2">
              <label>Confirm Mobile number</label>
              <div class="mobile-number item-table">
                <!-- <div class="tdcell code">
                  <span>+61</span><i class="icon-arrowLeft-2"></i>
                </div> -->
                <div class="tdcell phone">
                  <input
                    class="whitedisabled"
                    v-model="confirmPhone"
                    type="tel"
                    maxlength="10"
                    placeholder="Mobile number"
                    @blur="checkConfirm('phone')"
                  />
                  <!-- <i class="icon-arrowRight" v-if="$store.state.userInfo.registerType!=2"></i> -->
                </div>
                <div
                  class="tdcell"
                  style="width: 65px; padding-left: 10px; position: relative"
                >
                  <img
                    class="tick"
                    :src="tick"
                    v-if="$store.state.form.isPhoneValidation"
                  />
                  <!-- <span v-else class="Medium" @click="verifyEvent(2)" style="color: #00a1de; cursor: pointer;">Verify</span> -->
                </div>
              </div>
              <p class="tip red" v-if="errorConfirm">Mobile do not match</p>
              <!-- <p v-if="!$store.state.form.isPhoneValidation" class="verify-tip Medium">Pending for verification</p> -->
            </div>
            <!-- Mobile End -->

            <div class="form-item">
              <label class="required">Home Store</label>
              <div class="input-box" @click="showStoreList">
                <input
                  type="text"
                  class="whitedisabled pointer nocssdis"
                  disabled
                  v-model="selectStore.name"
                  placeholder="Home Store"
                />
                <i class="icon-arrowRight pointer"></i>
              </div>
            </div>
            <hr class="bar" />
            <!-- <div v-if="$store.state.verifyUserInfo">
              <div class="form-item" style="padding-top: 30px">
                <label>First Name</label>
                <div class="input-box">
                  <input
                    type="text"
                    class="whitedisabled"
                    disabled
                    v-model="$store.state.verifyUserInfo.firstName"
                  />
                </div>
              </div>
              <div class="form-item">
                <label>Last Name</label>
                <div class="input-box">
                  <input
                    type="text"
                    class="whitedisabled"
                    disabled
                    v-model="$store.state.verifyUserInfo.lastName"
                  />
                </div>
              </div>
              <div class="form-item">
                <label>Email</label>
                <div class="input-box">
                  <input
                    type="text"
                    class="whitedisabled"
                    disabled
                    v-model="$store.state.verifyUserInfo.email"
                  />
                </div>
              </div> -->
            <!-- <div class="form-item">
                <label>Physical Card</label>
                <div class="input-box">
                  <input
                    type="text"
                    class="whitedisabled"
                    disabled
                    v-model="$store.state.verifyUserInfo.physicalCardNumber"
                  />
                </div>
              </div> -->
            <!-- </div> -->
            <hr v-if="$store.state.verifyUserInfo" class="bar" />

            <div class="form-item media">
              <h2>
                Existing Physical Card (Optional)
                <p
                  class="font-16 Medium"
                  style="color: #666666; font-weight: normal; margin-top: 5px"
                >
                  (enter card number to link your card)
                </p>
              </h2>
              <div class="input-box" v-if="$store.state.appVersion">
                <input
                  type="text"
                  v-model="$store.state.userInfo.physicalCardNumber"
                  @blur="checkCardNum"
                  @input="phyInputEvent"
                  class="whitedisabled pointer"
                  placeholder="Card Number"
                />
                <i class="icon-card pointer"></i>
                <i class="icon-shape pointer" @click="goScanEvent"></i>
              </div>
              <div class="input-box" v-else>
                <input
                  type="text"
                  v-model="$store.state.userInfo.physicalCardNumber"
                  @blur="checkCardNum"
                  @input="phyInputEvent"
                  placeholder="Card Number"
                />
                <i class="icon-card pointer"></i>
              </div>
              <p class="tip red" v-if="errorCardNum">Number format is incorrect</p>
            </div>
            <hr class="bar" />
            <!-- <div class="form-item" style="padding-top: 30px">
              <label>Date of Birth (Optional)</label>
              <div class="input-box">
                <ion-datetime
                  v-model="$store.state.userInfo.birthday"
                  display-format="DD/MM/YYYY"
                  placeholder="Date of Birth"
                  @ionChange="(ev) => {changeBirthday(ev)}"
                ></ion-datetime>
              </div>
            </div> -->
            <div class="form-item" style="padding-top: 30px">
              <label>Date of Birth (Optional)</label>
              <van-icon
                id="trigger-button"
                name="question"
                size="24"
                style="margin-left: 10px; vertical-align: middle"
                color="#223b86"
              />
              <ion-popover
                :show-backdrop="false"
                trigger="trigger-button"
                mode="ios"
                :translucent="true"
              >
                <ion-content :forceOverscroll="false">
                  <div class="tip-date">
                    <p>
                      Some offers are 18+ restricted (liquor). Please confirm your DOB.
                    </p>
                  </div>
                </ion-content>
              </ion-popover>
              <!-- <div class="input-box" @click="showPicker = true">
                <input type="text" class="whitedisabled pointer" disabled v-model="$store.state.userInfo.birthday" placeholder="Date of Birth (Optional)" />
              </div> -->

              <!-- <div style="margin-top: 15px">
                <ion-datetime
                  locale="en"
                  class="birthDay"
                  :value="$store.state.userInfo.birthday"
                  v-model="$store.state.userInfo.birthday"
                  presentation="date"
                  mode="md"
                  display-format="DD/MM/YYYY"
                  @ionChange="
                    ev => {
                      changeBirthday(ev);
                    }
                  "
                ></ion-datetime>
              </div> -->
              <div class="date-input-container">
                <!-- <div class="input-box" style="width: 40px">
                  <input type="tel" maxlength="2" @blur="checkDate('DD')" class="p-l-5" v-model="birthdayDD" placeholder="DD" />
                </div> -->
                <!-- <span style="display: inline-block; margin: 0 10px">/</span> -->
                <div class="input-box" style="width: 50px">
                  <input
                    type="tel"
                    maxlength="2"
                    style="text-align: center"
                    @blur="checkDate('MM')"
                    class="p-l-5"
                    v-model="birthdayMM"
                    placeholder="MM"
                  />
                </div>
                <span style="display: inline-block; margin: 0 15px">/</span>
                <div class="input-box" style="width: 70px">
                  <input
                    type="tel"
                    maxlength="4"
                    style="text-align: center"
                    @blur="checkDate('YY')"
                    class="p-l-5"
                    v-model="birthdayYY"
                    placeholder="YYYY"
                  />
                </div>
              </div>
              <p class="tip red" v-if="errorFormatDate.join('') != ''">
                {{ errorFormatDate.join("") }} format is incorrect
              </p>
            </div>
            <div class="form-item" :class="{ bottom0: !$store.state.isPC }">
              <label>Referral Code (Optional)</label>
              <div class="input-box">
                <input
                  type="text"
                  v-model="$store.state.userInfo.referralCode"
                  placeholder="Referral Code (Optional)"
                />
              </div>
            </div>
            <hr class="bar" v-if="$store.state.isPC" />

            <div class="item-table flex-center-center pad-t-30">
              <div class="tdcell checkbox f-s-0">
                <van-checkbox
                  v-model="$store.state.userInfo.isPaperReceipt"
                  shape="square"
                  checked-color="#00a1de"
                ></van-checkbox>
              </div>
              <div class="tdcell">
                Yes I want digital receipts.
                <br />
                <span
                  >Digital receipts will be sent to the registered email address.</span
                >
              </div>
            </div>
            <div class="confirm-box">
              <p
                style="color: #f00"
                v-if="
                  !(
                    $store.state.userInfo.firstName &&
                    $store.state.userInfo.lastName &&
                    selectStore.name &&
                    birthdayPass &&
                    emailPass&&
                    !errorFormatEmail &&
                    !errorFormatPhone
                  )
                "
              >
                Please fill in all the mandatory fields
              </p>
              <ion-button
                expand="block"
                :disabled="
                  !(
                    $store.state.userInfo.firstName &&
                    $store.state.userInfo.lastName &&
                    selectStore.name &&
                    birthdayPass &&
                    emailPass&&
                    !errorFormatEmail &&
                    !errorFormatPhone
                  )
                "
                @click="confirmEvent"
                >Confirm</ion-button
              >
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonModal,
  IonPopover,
  IonInput,
} from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import storeListModel from "@/components/storeListModel.vue";
import config from "../config";
// import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
// import Datepicker from "@vuepic/vue-datepicker";
// import "@vuepic/vue-datepicker/dist/main.css";
export default {
  name: "setProfile",
  mixins: [config.globalMixin],
  components: {
    IonContent,
    IonPage,
    topbar,
    IonButton,
    IonModal,
    storeListModel,
    IonPopover,
    IonInput,
    // Datepicker,
  },
  computed: {
    birthday: function () {
      return (
        this.isPadZero(this.birthdayYY) +
        "/" +
        this.isPadZero(this.birthdayMM) +
        "/" +
        this.isPadZero(this.birthdayDD)
      );
    },
    birthdayPass: function () {
      if (this.birthdayMM == "" && this.birthdayYY == "") {
        return true;
      } else if (this.birthdayMM != "" && this.birthdayYY != "") {
        return true;
      } else {
        return false;
      }
    },
    emailPass: function () {
      if (this.$store.state.userInfo.registerType == 2) {
        if (this.$store.state.userInfo.isMarketingInfo || this.$store.state.userInfo.isPaperReceipt) {
          if (this.$store.state.userInfo.email === this.confirmEmail && this.$store.state.userInfo.email) {
            return true
          } else {
            return false
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  
  data() {
    return {
      isEmail: false,
      showStore: false,
      storeName: "",
      storeId: "",
      selectStore: {},
      isConfirm: false,
      confirmEmail: "",
      confirmPhone: "",
      errorConfirm: false,
      errorMobileConfirm: false,
      tick: require("@/assets/tick.png"),
      errorFormatPhone: false,
      errorFormatEmail: false,
      errorCardNum: false,
      showPicker: false,
      startDate: new Date("1990-1-1"),
      birthdayDD: "01",
      birthdayMM: "",
      birthdayYY: "",
      errorFormatDate: ["", "", ""],
      smallMM: [4, 6, 9, 11],
      canSendS: true,
      canSendE: true,
      canConfirmCall: true,
      logoutTimeOut: null,
    };
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/createAccount",
      });
    },
    isPadZero(num) {
      if (num.length < 2) {
        return num.padStart(2, "0");
      } else {
        return num;
      }
    },
    checkDate(type) {
      if (type == "MM") {
        if (this.birthdayMM > 0 && this.birthdayMM <= 12) {
          this.errorFormatDate[1] = "";
          return true;
        } else if (this.birthdayMM == "" && this.birthdayYY != "") {
          this.errorFormatDate[1] = " MM ";
          return false;
        } else if (this.birthdayMM == "" && this.birthdayYY == "") {
          this.errorFormatDate[1] = "";
          this.errorFormatDate[2] = "";
          return true;
        } else {
          this.errorFormatDate[1] = " MM ";
          return false;
        }
      } else if (type == "YY") {
        let nowYear = new Date().getFullYear();
        if (this.birthdayYY >= 1900 && this.birthdayYY <= nowYear) {
          this.errorFormatDate[2] = "";
          return true;
        } else if (this.birthdayMM != "" && this.birthdayYY == "") {
          this.errorFormatDate[2] = " YY ";
          return false;
        } else if (this.birthdayMM == "" && this.birthdayYY == "") {
          this.errorFormatDate[1] = "";
          this.errorFormatDate[2] = "";
          return true;
        } else {
          this.errorFormatDate[2] = " YY";
          return false;
        }
      }
    },
    async confirmEvent() {
      if (!this.canConfirmCall) return;
      this.canConfirmCall = false;

      this.$store.state.showLoading = true;

      let objdata = this.getAuthObj();
      if (this.$store.state.userInfo.email) {
        this.$store.state.userInfo.email = this.$store.state.userInfo.email
          .trim()
          .toLowerCase();
      }
      for (const key in this.$store.state.userInfo) {
        const detail = JSON.stringify(this.$store.state.userInfo[key]);
        objdata[key] = JSON.parse(detail);
      }
      objdata.storeId = this.selectStore.storeId;
      if (this.birthdayMM != "" || this.birthdayYY != "") {
        if (
          (this.birthdayYY % 4 == 0 && this.birthdayYY % 100 != 0) ||
          this.birthdayYY % 400 == 0
        ) {
          if (this.birthdayMM == 2 && this.birthdayDD > 29) {
            this.errorFormatDate[0] = "DD ";
            this.canConfirmCall = true;
            return;
          } else if (
            this.smallMM.indexOf(Number(this.birthdayMM)) > -1 &&
            this.birthdayDD > 30
          ) {
            this.errorFormatDate[0] = "DD ";
            this.canConfirmCall = true;
            return;
          }
        } else {
          if (this.birthdayMM == 2 && this.birthdayDD > 28) {
            this.errorFormatDate[0] = "DD ";
            this.canConfirmCall = true;
            return;
          } else if (
            this.smallMM.indexOf(Number(this.birthdayMM)) > -1 &&
            this.birthdayDD > 30
          ) {
            this.errorFormatDate[0] = "DD ";
            this.canConfirmCall = true;
            return;
          }
        }
        if (
          this.errorFormatDate.join("") == "" &&
          this.birthdayMM != "" &&
          this.birthdayYY != ""
        ) {
          objdata.birthday = this.formatDate(this.birthday, "YYYY-MM-DD") + " 00:00:00";
        } else {
          if (this.birthdayMM == "") {
            this.errorFormatDate[1] = "MM ";
          } else if (this.birthdayYY == "") {
            this.errorFormatDate[2] = "YY ";
          }
          this.canConfirmCall = true;
          return false;
        }
      }

      if (this.$store.state.appVersion) {
        objdata.loginSource = 1; //app
      } else {
        objdata.loginSource = 2; //web
      }
      objdata.isPaperReceipt = !objdata.isPaperReceipt;

      this.logoutTimeOut = setTimeout(() => {
        this.timeOutLogout();
      }, 120000);

      let data = null;
      if (this.$store.state.needVerify) {
        objdata.apiUrl = this.apiConfig.bindUserProfile;
        data = await this.$store.dispatch("apiPutEvent", objdata);
        this.sendGAEvent("Click", "Set Profile", "Confirm", this.$store.state.needVerify);
      } else {
        objdata.apiUrl = this.apiConfig.register;
        data = await this.$store.dispatch("apiEvent", objdata);
        // data = {}
        this.sendGAEvent("Click", "Set Profile", "Confirm", this.$store.state.needVerify);
      }
      if (!data) {
        this.sendGAEvent("Click", "Set Profile", "Register Failure");
        this.canConfirmCall = true;
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.sendGAEvent("Click", "Set Profile", "Register Failure");
        this.timeoutEvent();
        this.canConfirmCall = true;
        return;
      }
      this.sendGAEvent("Click", "Set Profile", "Register Successful");
      this.canConfirmCall = true;

      this.$store.state.form.confirmEmail = "";
      this.$store.state.form.confirmPhone = "";
      this.$store.state.form.isEmailValidation = "";
      this.$store.state.form.isPhoneValidation = "";
      this.$store.state.form.isConfirm = "";

      this.$store.state.userInfo.firstName = "";
      this.$store.state.userInfo.lastName = "";
      this.$store.state.userInfo.email = "";
      this.$store.state.verifyUserInfo = null;

      this.$store.state.needVerify = false;
      this.$store.state.form.token = data.data.result.token;
      this.$store.state.userInfo = {
        phoneAreaCode: "+61",
        isMarketingInfo: false,
        isPaperReceipt: false,
      };

      this.getUserPofileEvent(() => {
        this.getMemberDetail(false, 1);
      });
    },
    showStoreList() {
      this.showStore = true;
    },
    goScanEvent() {
      this.$store.state.profileInfo = {
        firstName: this.$store.state.userInfo.firstName,
        lastName: this.$store.state.userInfo.lastName,
        confirmEmail: this.confirmEmail,
        email: this.$store.state.userInfo.email.trim().toLowerCase(),
        phone: this.$store.state.userInfo.phone,
        confirmPhone: this.confirmPhone,
        storeId: this.selectStore.storeId,
        storeName: this.selectStore.name,
        birthdayDD: this.birthdayDD,
        birthdayMM: this.birthdayMM,
        referralCode: this.$store.state.userInfo.referralCode,
        isPaperReceipt: this.$store.state.userInfo.isPaperReceipt,
      };
      this.sendGAEvent("Click", "Set Profile", "Scan Code");
      this.$router.push({
        path: "/scanCode",
      });
    },
    hidemodel() {
      this.showStore = false;
    },
    selectEvent(store) {
      if (store) {
        this.selectStore = store;
      }
      this.showStore = false;
    },
    goBindEvent(type) {
      if (type == this.$store.state.userInfo.registerType) {
        return;
      }
      if (type == 1) {
        this.sendGAEvent("Click", "Set Profile", "Set Email");
      } else if (type == 2) {
        this.sendGAEvent("Click", "Set Profile", "Set Mobile");
      }
      this.$router.push({
        path: "/changeMobileEmail/" + type,
      });
    },
    verifyEvent(type) {
      this.$store.state.form.isConfirm = this.isConfirm;
      this.$store.state.form.profileVerifyType = "setProfile";
      if (type == 1) {
        this.$store.state.form.email = this.$store.state.userInfo.email
          .trim()
          .toLowerCase();
        this.sendEmailEvent();
      } else {
        this.$store.state.form.phone = this.$store.state.userInfo.phone;
        this.sendSmsEvent();
      }
    },
    async sendSmsEvent() {
      if (!this.canSendS) return;
      this.canSendS = false;
      if (this.errorConfirm || this.errorFormatPhone) return;
      const objdata = this.getAuthObj();
      objdata.phone = this.confirmPhone;
      objdata.phoneAreaCode = this.$store.state.userInfo.phoneAreaCode;
      objdata.apiUrl = this.apiConfig.sendSms;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendS = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.form.confirmPhone = this.confirmPhone;
      this.$router.push({
        path: "/verifyCode/3/2",
      });
    },
    async sendEmailEvent() {
      if (!this.canSendE) return;
      this.canSendE = false;
      if (this.errorConfirm || this.errorFormatEmail) return;
      const objdata = this.getAuthObj();
      objdata.email = this.confirmEmail.trim().toLowerCase();
      objdata.apiUrl = this.apiConfig.sendVerifyEmail;
      objdata.verifyType = 3;
      const data = await this.$store.dispatch("apiEvent", objdata);
      setTimeout(() => {
        this.canSendE = true;
      }, 1500);
      if (!data) {
        return;
      }

      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.$store.state.form.confirmEmail = this.confirmEmail;
      this.$router.push({
        path: "/verifyCode/3/1",
      });
    },
    phyInputEvent() {
      this.errorCardNum = false;
    },
    checkCardNum() {
      let numStr = String(this.$store.state.userInfo.physicalCardNumber);
      let numNum = this.$store.state.userInfo.physicalCardNumber;
      let numReg = /^\+?(0|[1-9]\d*)$/;
      if (numStr.length == 0 || numStr == "null") {
        this.errorCardNum = false;
        return;
      }
      console.log(numReg.test(numNum));
      if (!numReg.test(numNum)) {
        this.errorCardNum = true;
        return;
      }
      if (
        numStr.length != 13 ||
        (numStr.slice(0, 4) != "2000" && numStr.slice(0, 4) != "2001")
      ) {
        this.errorCardNum = true;
      } else {
        this.errorCardNum = false;
      }
    },
    changeBirthday(event) {
      let date = event.detail.value.split("T")[0];
      this.birthday = date + " 00:00:00";
      // this.$store.state.userInfo.birthday = this.formatDate(event.detail.value, "YYYY-MM-DD") + " 00:00:00"
    },
    checkConfirm(type) {
      let confirmType = type == "phone" ? "Phone" : "Email";
      if (
        this.$store.state.userInfo[type].toLowerCase() ==
        this[`confirm${confirmType}`].toLowerCase()
      ) {
        this.errorConfirm = false;
        if (!this.errorFormat) {
          this.isConfirm = true;
        } else {
          this.isConfirm = false;
        }
      } else {
        this.isConfirm = false;
        this.errorConfirm = true;
      }
    },
    checkFormat(type) {
      let confirmType = type == "phone" ? "Phone" : "Email";
      if (
        this.$store.state.userInfo[type] == "" ||
        this.$store.state.userInfo[type] == undefined
      ) {
        return;
      }
      if (!this.$store.state[`${type}Patrn`].test(this.$store.state.userInfo[type])) {
        this[`errorFormat${confirmType}`] = true;
      }
      if (this[`confirm${confirmType}`]) {
        this.checkConfirm(type);
      }
    },
    selectDate(value) {
      this.$store.state.userInfo.birthday = value;
      this.showPicker = false;
    },
  },
  ionViewWillEnter() {
    this.canConfirmCall = true;
    this.isEmail = false;
    this.showStore = false;
    this.storeName = "";
    this.storeId = "";
    this.selectStore = {};
    this.$store.state.userInfo.firstName = "";
    this.$store.state.userInfo.lastName = "";
    this.$store.state.userInfo.referralCode = "";
    this.$store.state.userInfo.isPaperReceipt = false;
    this.isConfirm = this.$store.state.form.isConfirm
      ? this.$store.state.form.isConfirm
      : false;
    this.confirmEmail = this.$store.state.form.confirmEmail
      ? this.$store.state.form.confirmEmail
      : "";
    this.confirmPhone = this.$store.state.form.confirmPhone
      ? this.$store.state.form.confirmPhone
      : "";
    this.errorConfirm = false;
    if (this.$store.state.verifyUserInfo) {
      let { firstName, lastName, email } = this.$store.state.verifyUserInfo;
      this.$store.state.userInfo.firstName = firstName;
      this.$store.state.userInfo.lastName = lastName;
      this.$store.state.userInfo.email = email.trim().toLowerCase();
    }
    if (this.$store.state.profileInfo) {
      this.$store.state.userInfo.firstName = this.$store.state.profileInfo.firstName;
      this.$store.state.userInfo.lastName = this.$store.state.profileInfo.lastName;
      this.$store.state.userInfo.email = this.$store.state.profileInfo.email
        .trim()
        .toLowerCase();
      this.$store.state.userInfo.phone = this.$store.state.profileInfo.phone;
      this.$store.state.userInfo.referralCode = this.$store.state.profileInfo.referralCode;
      this.$store.state.userInfo.isPaperReceipt = this.$store.state.profileInfo.isPaperReceipt;
      this.confirmEmail = this.$store.state.profileInfo.confirmEmail;
      this.confirmPhone = this.$store.state.profileInfo.confirmPhone;
      this.selectStore.name = this.$store.state.profileInfo.storeName;
      this.selectStore.storeId = this.$store.state.profileInfo.storeId;
      this.birthdayDD = this.$store.state.profileInfo.birthdayDD;
      this.birthdayMM = this.$store.state.profileInfo.birthdayMM;
    }
    this.$store.state.profileInfo = null;
    this.$store.state.exitApp = false;
    this.sendGAPageView("Setup Profile");
  },
  ionViewWillLeave() {
    if (this.logoutTimeOut != null) {
      clearTimeout(this.logoutTimeOut);
    }
  },
};
</script>
<style scoped>
ion-popover {
  --background: #3e3e3e;
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: 300px;
}

ion-popover ion-content {
  --background: #3e3e3e;
}

ion-popover::part(arrow)::after {
  background-color: #3e3e3e;
}
</style>
